import styled from 'styled-components';

export const ScWideSliderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 20px;
	}
	& .slick-track {
		display: flex;
		flex-direction: row;
	}
	.active-dot {
		background: ${({ theme }) => theme.colors.darkText};
		@media (min-width: 1220px) {
			background: ${({ theme }) => theme.colors.strongWhite};
		}
	}
`;

interface IScWideSliderTextWrapper {
	color?: string;
}

export const ScWideSliderTextWrapper = styled.div<IScWideSliderTextWrapper>`
	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: center;
	position: relative;
	min-height: 190px;
	border-radius: 10px;
	padding: 45px 45px 110px 45px;
	box-sizing: border-box;
	background: ${({ theme, color }) => color || theme.colors.peach};
	button {
		margin-bottom: 30px;
	}
	div {
		max-width: 228px;
	}
	@media (min-width: 768px) {
		min-height: 220px;
		div {
			max-width: 100%;
		}
	}
	@media (min-width: 1220px) {
		gap: 40px;
		border-radius: 30px;
		padding: 40px 36px 70px 36px;
		min-height: 690px;
		height: fit-content;
	}
`;

interface IScWideSliderSlideWrapper {
	isLarge?: boolean;
}

export const ScWideSliderSlideWrapper = styled.div<IScWideSliderSlideWrapper>`
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 40px 55px;
	box-sizing: border-box;
	height: 100%;
	min-height: 360px;

	img {
		width: ${({ isLarge }) => (isLarge ? '240px' : '105px')};
		height: 100%;
	}

	@media (min-width: 350px) {
		padding: 5px 20px 15px 20px;
		img {
			max-width: 470px;
			height: 100%;
			width: 100%;
		}
	}

	@media (min-width: 768px) {
		min-height: 550px;
		height: 500px;

		padding: ${({ isLarge }) => (isLarge ? '12px 90px 45px 90px' : '5px 36px 15px 36px')};
		img {
			height: 100%;
			width: 100%;
			max-width: none !important;
		}
	}
	@media (min-width: 900px) {
		min-height: 690px;
		height: 690px;
		padding: ${({ isLarge }) => (isLarge ? '12px 90px 45px 90px' : '5px 36px 15px 36px')};
		img {
			height: 100%;
			width: 100%;
		}
	}
	@media (min-width: 1220px) {
		min-height: 690px;
		height: 690px;
		padding: ${({ isLarge }) => (isLarge ? '12px 90px 45px 90px' : '5px 36px 15px 36px')};
		img {
			height: 100%;
			width: 100%;
			max-width: 836px;
			max-height: 656px;
      object-fit: contain;
		}
	}
`;

export const ScWideSliderWrapper = styled.div`
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.wheat};
	@media (min-width: 1220px) {
		border-radius: 30px;
		max-width: calc((100vw - 120px) / 1.5);
	}
	@media (min-width: 1920px) {
		max-width: 1200px;
	}
`;

export const ScWideSliderSlideTitle = styled.h6`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	font: ${({ theme }) => theme.fonts.heading.xs};
	text-align: center;
	@media (min-width: 768px) {
		margin: 0;
		font: ${({ theme }) => theme.fonts.input.large};
	}
	@media (min-width: 1220px) {
		text-align: left;
	}
`;

export const ScWideSliderSlideDots = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	position: absolute;
	bottom: 20px;
	@media (min-width: 1220px) {
		bottom: 40px;
	}
	@media (min-width: 1920) {
		gap: 20px;
	}
`;

export const ScWideSliderSlideDot = styled.div`
	width: 18px;
	height: 5px;
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.strongWhite};
	cursor: pointer;
	@media (min-width: 1220px) {
		background: ${({ theme }) => theme.colors.darkText};
		width: 24px;
		height: 12px;
	}
`;
